import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const ApplicationSecurityTestingPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  wcasection,
  wsysection,
  wivsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" blogservice={true} />
      <HeroOther data={hero} />
      <section className="section custom-section">
        <div className="container" style={{ textAlign: 'center' }}>
          <SubTitle title={contactus.title} style={{ fontSize: '1.2rem' }} />
          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
              <button className="btn-light">{contactus.buttontxt} &#10230;</button>
            </a>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <Title title={aboutsection.title} color={aboutsection.textcolor} />
          <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
          <div style={{ textAlign: 'center' }}>
            <SubTitle title={aboutsection.subheader1} color={aboutsection.textcolor} margin="0 0 1.5rem" />
          </div>
          <div className="columns" >
            <div className="column is-6">
              <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="0 0 1rem" />
              <div style={{ fontWeight: '500' }}>
                <Descriptions descriptions={['You’re at the right place!']} color={aboutsection.textcolor} />
              </div>
            </div>
            <div className="column is-6 mobile-center aboutsection_img_col">
              <div className="aboutsection_img">
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section wcasection custom-section" style={{ background: `${wcasection.sectionbg}` }}>
        <div className="container">
          <div style={{ textAlign: 'center' }}>
            <SubTitle title={wcasection.subheader1} color={wcasection.textcolor} margin="1.5rem 0" />
          </div>
          <div className="columns">
            <div className="column is-6 wcasection_img_col">
              <div className="wcasection_img">
                <PreviewCompatibleImage imageInfo={{ image: '/img/playing_boy.png' }} />
              </div>
            </div>
            <div className="column is-6">
              <Descriptions descriptions={wcasection.descriptions1} color={wcasection.textcolor} margin="1rem 0" />
              <div style={{ marginTop: '1rem', color: '#fff' }}>
                <a href={contactus.buttonlink} target="_blank" rel="noopener noreferrer">
                  <button>Get Started Today &#10230;</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Brands />
      <WhyChooseUs
        title="With our application security testing services, you can rest assured that your applications are secure."
        list={[{
          title: 'We understand code and how applications are built.',
          para: 'All of our security engineers have extensive experience in software development. We understand code. This knowledge enables us to thoroughly understand how your application works and how to best test your applications to identify security vulnerabilities.'
        }, {
          title: 'We are security experts.',
          para: 'Our engineers regularly instruct for SANS, where they teach developers and organizations how to build and test their applications to ensure they are secure.',
        }, {
          title: 'We are versatile.',
          para: 'Our engineers test critical applications across numerous verticals, which include intelligence, defense, finance, insurance, healthcare, and other industries.'
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Secure Your Applications?',
        subtitle: '',
        para: [
          'Our security experts can help you develop a robust and [secure development lifecycle (SSDLC)](/secure-software-development-life-cycle).',
        ],
        linkTitle: 'Talk to Our Experts Today'
      }} />
    </div>
  )

ApplicationSecurityTestingPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  wcasection: PropTypes.object,
  wsysection: PropTypes.object,
  wivsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const ApplicationSecurityTestingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ApplicationSecurityTestingPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        wcasection={frontmatter.wcasection}
        wsysection={frontmatter.wsysection}
        wivsection={frontmatter.wivsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

ApplicationSecurityTestingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ApplicationSecurityTestingPage

export const pageQuery = graphql`
  query ApplicationSecurityTestingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "application-security-testing" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions
          subheader2
          abovedescriptions
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wcasection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions1
          subheader2
          descriptions2
          subheader3
          descriptions3
        }
        wsysection {
          title
          secondtitle
          subheader1
          sidedescriptions {
            title
            list {
              title
              description
            }
          }
          descriptions {
            list {
              title
              description
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wivsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          sidedescriptions
          bottomdescriptions
          subheader2
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
